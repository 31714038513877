<template>
  <v-sheet class="page-sheet rounded-20">
    <v-card class="yellow lighten-5 fill-height rounded-20 back-lic">
      <v-card-title>
        <v-spacer></v-spacer>
        <i18n path="home.lictitle" />
        <v-spacer></v-spacer>
        <v-btn icon color="error" to="/">
          <v-icon large>mdi-close-circle</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row justify="center" class="mt-10" dense>
          <v-col cols="5" align-self="center">
            <v-form ref="prebuy">
              <v-row justify="center" class="white elevation-10 px-4 pb-4 back-lic-1" dense>
                <v-col cols="12">
                  <v-text-field
                    prepend-inner-icon="mdi-account"
                    v-model="name"
                    name="name"
                    :label="$t('license.name')"
                    :rules="required"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-inner-icon="mdi-email"
                    v-model="email"
                    name="email"
                    type="email"
                    :label="$t('license.email')"
                    :rules="required"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-inner-icon="mdi-phone"
                    v-model="phone"
                    name="phone"
                    :label="$t('license.phone')"
                    :rules="required"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="addr"
                    prepend-inner-icon="mdi-home-city"
                    name="address"
                    :label="$t('license.address')"
                    :rules="required"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="regnr"
                    prepend-inner-icon="mdi-alpha-r-circle-outline"
                    name="regnr"
                    :label="$t('license.regnr')"
                    :rules="required"
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="4" align-self="center">
                  <v-text-field
                    class="days-to-buy"
                    v-model="days"
                    type="number"
                    :label="$t('license.days')"
                    :rules="required"
                  ></v-text-field>
                </v-col>-->
                <v-col cols="12" align-self="center">
                  <v-row justify="space-around" dense>
                    <v-col cols="12" class="text-center text-h6">
                      <i18n path="license.selectplan" />
                    </v-col>
                    <template v-for="(v,k) in tariffs">
                      <v-col
                        align-self="center"
                        :class="plan==k?'selected-plan elevation-4':''"
                        :key="k"
                      >
                        <v-tooltip top>
                          <template #activator="{on, attrs}">
                            <v-img
                              :src="'/img/'+k+'.png'"
                              width="60px"
                              @click="plan=k"
                              class="img-license-img"
                              v-on="on"
                              v-bind="attrs"
                            ></v-img>
                          </template>
                          <i18n :path="'license.planhint.'+k" />
                        </v-tooltip>
                        <div class="text-center">
                          <i18n :path="'license.planname.'+k" />
                        </div>
                      </v-col>
                    </template>
                    <!-- <v-col align-self="center" :class="plan==1?'selected-plan':''">
                      <v-tooltip top>
                        <template #activator="{on, attrs}">
                          <v-img
                            src="~@/assets/lic-6m-h.png"
                            width="60px"
                            @click="plan=1"
                            class="img-license-img"
                            v-on="on"
                            v-bind="attrs"
                          ></v-img>
                        </template>
                        <i18n path="license.add6month" />
                      </v-tooltip>
                      <div class="text-center">
                        <i18n path="license.add6month" />
                      </div>
                    </v-col>
                    <v-col align-self="center" :class="plan==2?'selected-plan':''">
                      <v-tooltip top>
                        <template #activator="{on, attrs}">
                          <v-img
                            src="~@/assets/lic-12m-h.png"
                            width="60px"
                            @click="plan=2"
                            class="img-license-img"
                            v-on="on"
                            v-bind="attrs"
                          ></v-img>
                        </template>
                        <i18n path="license.add12month" />
                      </v-tooltip>
                      <div class="text-center">
                        <i18n path="license.add12month" />
                      </div>
                    </v-col>-->
                  </v-row>
                </v-col>
                <v-col cols="8" class="text-center mt-8" align-self="center">
                  <i18n path="license.tobuy" class="text-h6" v-if="tariffs">
                    <template #days>
                      <span class="text-h5 primary--text">{{tariffs[plan].days}}</span>
                    </template>
                    <template #price>
                      <span class="text-h5 success--text">{{tariffs[plan].price | currency}}</span>
                    </template>
                  </i18n>
                  <!-- <span class="text-h4">{{calculatedPrice}}</span>
                  <span class="text-h4">{{calculatedPrice}}</span>-->
                </v-col>
                <v-col cols="4" class="text-right mt-8" align-self="center">
                  <v-btn
                    x-large
                    color="primary"
                    @click.prevent="getLicense"
                    class="center-in-div elevation-10"
                    rounded
                    :disabled="working"
                  >
                    <v-icon left>mdi-cart</v-icon>
                    <i18n path="home.licbtn" />
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-sheet>
</template>

<script>
export default {
  name: "BuyLicense",
  data() {
    return {
      working: false,
      id: null,
      plan: null,
      email: null,
      addr: null,
      name: null,
      phone: null,
      regnr: null,
      tariffs: null,
      required: [(v) => !!v || this.$t("error.required")],
    };
  },
  computed: {
    // calculatedPrice() {
    //   let ret = this.$t("home.totalprice");
    //   ret += this.$root.$options.filters["currency"](this.days * 50);
    //   return ret;
    // },
  },
  methods: {
    getLicense() {
      if (!this.$refs.prebuy.validate() || this.plan == null) return;
      this.working = true;
      this.$api
        .apiPostRequest("v1/license/prebuy", {
          email: this.email,
          addr: this.addr,
          name: this.name,
          phone: this.phone,
          regnr: this.regnr,
          plan: this.plan,
        })
        .then((r) => window.open(r, "_self"))
        .finally(() => (this.working = false));
    },
  },
  mounted() {
    fetch(this.$api.api + "/v1/license/tariffs")
      .then((r) => r.json())
      .then((d) => {
        this.tariffs = { ...d };
        try {
          this.plan = Object.keys(d)[0];
        } catch (error) {
          console.log(error);
        }
      });
  },
};
</script>

<style scoped>
.rounded-20 {
  border-radius: 20px !important;
}
.center-in-div {
  margin: auto;
}
.back-lic {
  background: url("~@/assets/img/silver1.png");
  border-radius: 25px;
}
.back-lic-1 {
  background: url("~@/assets/img/lic15.png") no-repeat;
  background-position: center;
  border-radius: 25px;
}
.days-to-buy {
  width: 100%;
}
.days-to-buy >>> input {
  font-size: 24px;
  text-align: right;
}
.img-license {
  align-self: center;
}
.img-license-img {
  /* -webkit-box-shadow: 5px 5px 15px 5px #000000;
  box-shadow: 5px 5px 15px 5px #000000; */
  cursor: pointer;
  margin: auto;
}
.selected-plan {
  border: 2px solid green;
  background-color: antiquewhite;
}
</style>